/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as HomeImport } from './routes/home'
import { Route as IndexImport } from './routes/index'
import { Route as HomeSessionImport } from './routes/home/session'
import { Route as HomeProfileImport } from './routes/home/profile'
import { Route as HomeDashboardImport } from './routes/home/dashboard'

// Create/Update Routes

const HomeRoute = HomeImport.update({
  id: '/home',
  path: '/home',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const HomeSessionRoute = HomeSessionImport.update({
  id: '/session',
  path: '/session',
  getParentRoute: () => HomeRoute,
} as any)

const HomeProfileRoute = HomeProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => HomeRoute,
} as any)

const HomeDashboardRoute = HomeDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => HomeRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/home': {
      id: '/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof HomeImport
      parentRoute: typeof rootRoute
    }
    '/home/dashboard': {
      id: '/home/dashboard'
      path: '/dashboard'
      fullPath: '/home/dashboard'
      preLoaderRoute: typeof HomeDashboardImport
      parentRoute: typeof HomeImport
    }
    '/home/profile': {
      id: '/home/profile'
      path: '/profile'
      fullPath: '/home/profile'
      preLoaderRoute: typeof HomeProfileImport
      parentRoute: typeof HomeImport
    }
    '/home/session': {
      id: '/home/session'
      path: '/session'
      fullPath: '/home/session'
      preLoaderRoute: typeof HomeSessionImport
      parentRoute: typeof HomeImport
    }
  }
}

// Create and export the route tree

interface HomeRouteChildren {
  HomeDashboardRoute: typeof HomeDashboardRoute
  HomeProfileRoute: typeof HomeProfileRoute
  HomeSessionRoute: typeof HomeSessionRoute
}

const HomeRouteChildren: HomeRouteChildren = {
  HomeDashboardRoute: HomeDashboardRoute,
  HomeProfileRoute: HomeProfileRoute,
  HomeSessionRoute: HomeSessionRoute,
}

const HomeRouteWithChildren = HomeRoute._addFileChildren(HomeRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/home': typeof HomeRouteWithChildren
  '/home/dashboard': typeof HomeDashboardRoute
  '/home/profile': typeof HomeProfileRoute
  '/home/session': typeof HomeSessionRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/home': typeof HomeRouteWithChildren
  '/home/dashboard': typeof HomeDashboardRoute
  '/home/profile': typeof HomeProfileRoute
  '/home/session': typeof HomeSessionRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/home': typeof HomeRouteWithChildren
  '/home/dashboard': typeof HomeDashboardRoute
  '/home/profile': typeof HomeProfileRoute
  '/home/session': typeof HomeSessionRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/home'
    | '/home/dashboard'
    | '/home/profile'
    | '/home/session'
  fileRoutesByTo: FileRoutesByTo
  to: '/' | '/home' | '/home/dashboard' | '/home/profile' | '/home/session'
  id:
    | '__root__'
    | '/'
    | '/home'
    | '/home/dashboard'
    | '/home/profile'
    | '/home/session'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  HomeRoute: typeof HomeRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  HomeRoute: HomeRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/home"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/home": {
      "filePath": "home.tsx",
      "children": [
        "/home/dashboard",
        "/home/profile",
        "/home/session"
      ]
    },
    "/home/dashboard": {
      "filePath": "home/dashboard.tsx",
      "parent": "/home"
    },
    "/home/profile": {
      "filePath": "home/profile.tsx",
      "parent": "/home"
    },
    "/home/session": {
      "filePath": "home/session.tsx",
      "parent": "/home"
    }
  }
}
ROUTE_MANIFEST_END */
