import * as Sentry from "@sentry/react";
import { env } from "@/env";
import { logger } from "./logger";
import { getRouter } from "@/router";

export const initSentry = () => {
  const router = getRouter();
  logger.info("Initializing Sentry");
  Sentry.init({
    dsn: env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.tanstackRouterBrowserTracingIntegration(router),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/justdinder\.me\/api/,
      /^https:\/\/justdinder\.me/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};

export const closeSentry = () => {
  logger.info("Closing Sentry");
  Sentry.close();
};
