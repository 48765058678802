import { ComponentProps } from "react";

type PictureProps = {
  src: string;
  alt: string;
  className?: string;
  imgClassName?: string;
  width?: number;
  height?: number;
} & Omit<ComponentProps<"picture">, "src">;

export default function Picture({
  src,
  alt,
  className,
  imgClassName,
  width,
  height,
  ...props
}: PictureProps) {
  const baseSrc = src.replace(/\.(jpg|jpeg|png)$/, "");

  return (
    <picture className={className} {...props}>
      <source srcSet={`/assets/optimized/${baseSrc}.avif`} type="image/avif" />
      <source srcSet={`/assets/optimized/${baseSrc}.webp`} type="image/webp" />
      <img
        src={`/assets/images/${src}`}
        alt={alt}
        className={imgClassName}
        width={width}
        height={height}
      />
    </picture>
  );
}
