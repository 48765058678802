import { useState, useCallback, useRef } from "react";
import Picture from "./picture";
import { MotionDiv } from "@/types/motion";
import { Pizza, Sandwich, Utensils } from "lucide-react";

interface ProductSectionProps {
  title: string;
  description: string;
  image: string;
  flipText: string;
  showBackground?: boolean;
  reverse?: boolean;
}

export default function ProductSection({
  title,
  description,
  image,
  flipText,
  showBackground,
  reverse = false,
}: ProductSectionProps) {
  const [isFlipped, setIsFlipped] = useState(false);
  const lastFlipTime = useRef(0);
  const flipCooldown = 500;

  const handleFlip = useCallback((flip: boolean) => {
    const currentTime = Date.now();
    if (currentTime - lastFlipTime.current > flipCooldown) {
      setIsFlipped(flip);
      lastFlipTime.current = currentTime;
    }
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  const floatingAnimation = {
    y: [0, -10, 0],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

  return (
    <section
      className={`relative w-full py-24 lg:py-32 ${
        showBackground
          ? "bg-gradient-to-br from-gray-700 to-black"
          : "bg-gradient-to-br from-black to-gray-700"
      }`}
    >
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-4 top-1/4 h-96 w-96 rounded-full bg-yellow-400/5 blur-3xl" />
        <div className="absolute -right-4 bottom-1/4 h-96 w-96 rounded-full bg-yellow-400/5 blur-3xl" />
        <MotionDiv
          className="absolute left-[10%] top-[20%] text-yellow-400/20"
          animate={floatingAnimation}
        >
          <Utensils size={48} />
        </MotionDiv>
        <MotionDiv
          className="absolute right-[15%] top-[40%] text-yellow-400/20"
          animate={floatingAnimation}
          transition={{ delay: 0.5 }}
        >
          <Pizza size={64} />
        </MotionDiv>
        <MotionDiv
          className="absolute bottom-[30%] left-[20%] text-yellow-400/20"
          animate={floatingAnimation}
          transition={{ delay: 1 }}
        >
          <Sandwich size={56} />
        </MotionDiv>

        <div className="absolute inset-0 opacity-5">
          <div className="absolute left-1/4 top-0 h-full w-px bg-gradient-to-b from-transparent via-yellow-400 to-transparent" />
          <div className="absolute right-1/3 top-0 h-full w-px bg-gradient-to-b from-transparent via-yellow-400 to-transparent" />
        </div>
      </div>
      <MotionDiv
        className={`container mx-auto flex min-h-[60vh] flex-col items-center gap-12 px-6 md:gap-20 ${
          reverse ? "md:flex-row-reverse" : "md:flex-row"
        }`}
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <MotionDiv className="relative w-full md:w-1/2" variants={itemVariants}>
          <div className="relative max-w-xl">
            <div className="absolute -left-6 top-0 h-20 w-1 bg-yellow-400" />

            <h2 className="mb-8 text-4xl font-bold leading-tight text-white md:text-5xl lg:text-6xl">
              {title}
            </h2>
            <p className="text-lg leading-relaxed text-gray-300 md:text-xl">
              {description}
            </p>
          </div>
        </MotionDiv>
        <MotionDiv className="flex w-full items-center lg:w-1/2" variants={itemVariants}>
          <div className="w-full">
            <div className="group relative mx-auto w-full max-w-lg">
              <div className="absolute -bottom-4 -right-4 h-full w-full rounded-2xl border border-yellow-400/20 bg-yellow-400/5" />
              <div className="relative aspect-video w-full">
                <MotionDiv
                  className="absolute inset-0"
                  initial={false}
                  animate={{ rotateY: isFlipped ? 180 : 0 }}
                  transition={{
                    duration: 0.6,
                    ease: "easeInOut",
                    transformPerspective: 1200,
                  }}
                  onHoverStart={() => handleFlip(true)}
                  onHoverEnd={() => handleFlip(false)}
                  onClick={() => handleFlip(!isFlipped)}
                  style={{ transformStyle: "preserve-3d" }}
                >
                  <div className="backface-hidden absolute inset-0">
                    <Picture
                      src={image}
                      alt={title}
                      imgClassName="h-full w-full rounded-2xl object-cover shadow-lg transition-transform duration-300 group-hover:scale-[1.02]"
                      width={1200}
                      height={675}
                    />
                    <div className="absolute inset-0 rounded-2xl bg-gradient-to-t from-black/40 to-transparent" />
                  </div>
                  <div className="rotateY-180 backface-hidden absolute inset-0 flex items-center justify-center rounded-2xl bg-gradient-to-br from-yellow-400 to-yellow-500 p-8 shadow-lg">
                    <p className="text-center text-2xl font-bold text-black md:text-3xl">
                      {flipText}
                    </p>
                  </div>
                </MotionDiv>
              </div>
            </div>
          </div>
        </MotionDiv>
      </MotionDiv>
    </section>
  );
}
