import { createEnv } from "@t3-oss/env-core";
import { z, ZodError } from "zod";

export const env = createEnv({
  clientPrefix: "VITE_",
  client: {
    VITE_CONVEX_URL: z.string().url(),
    VITE_PUBLIC_APP_NAME: z.string().default("Dinder"),
    VITE_SENTRY_DSN: z.string(),
    VITE_POSTHOG_KEY: z.string(),
    VITE_POSTHOG_HOST: z.string(),
  },
  runtimeEnv: {
    VITE_CONVEX_URL: import.meta.env.VITE_CONVEX_URL,
    VITE_PUBLIC_APP_NAME: import.meta.env.VITE_PUBLIC_APP_NAME,
    VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    VITE_POSTHOG_KEY: import.meta.env.VITE_POSTHOG_KEY,
    VITE_POSTHOG_HOST: import.meta.env.VITE_POSTHOG_HOST,
  },
  isServer: typeof window === "undefined",
  emptyStringAsUndefined: true,
  onValidationError: (error: ZodError) => {
    console.error("❌ Invalid environment variables:", error.flatten().fieldErrors);
    throw new Error("Invalid environment variables");
  },
  onInvalidAccess: (variable: string) => {
    throw new Error(
      `❌ Attempted to access a server-side environment variable on the client ${variable}`,
    );
  },
});
