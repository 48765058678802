import posthog from "posthog-js";
import { initPostHog } from "./posthog";
import { closeSentry, initSentry } from "./sentry";
import { logger } from "./logger";

type AnalyticsService = {
  init: (consent: boolean) => void;
  cleanup: () => void;
};

export const initAnalytics = (consent: boolean) => {
  analyticsServices.forEach((service) => {
    logger.info(`Initializing ${service.constructor.name}`);
    service.init(consent);
  });
};

export const analyticsServices: AnalyticsService[] =
  process.env.NODE_ENV === "development"
    ? [
        {
          init: () => logger.info("Analytics disabled in development"),
          cleanup: () => {},
        },
      ]
    : [
        {
          init: (consent: boolean) => {
            if (consent) {
              initSentry();
            } else {
              closeSentry();
            }
          },
          cleanup: closeSentry,
        },
        {
          init: (consent: boolean) => {
            initPostHog();

            if (consent) {
              posthog.opt_in_capturing();
              posthog.set_config({
                persistence: "localStorage+cookie",
              });
            } else {
              posthog.opt_out_capturing();
              posthog.set_config({
                persistence: "memory",
              });
            }
          },
          cleanup: () => {
            posthog.opt_out_capturing();
          },
        },
      ];
