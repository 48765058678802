import posthog from "posthog-js";
import { env } from "@/env";

export function initPostHog() {
  posthog.init(env.VITE_POSTHOG_KEY, {
    api_host: env.VITE_POSTHOG_HOST,
    person_profiles: "identified_only",
    loaded: (posthog) => {
      if (!import.meta.env.PROD) {
        posthog.debug();
      }
    },
  });
  return posthog;
}
