import { Outlet, ScrollRestoration } from "@tanstack/react-router";
import { Meta, Scripts } from "@tanstack/start";
import type { ReactNode } from "react";
import { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext } from "@tanstack/react-router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { seo } from "@/utils/seo";
import { DefaultCatchBoundary } from "@/components/default-catch-boundary";
import { NotFound } from "@/components/not-found";
import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { cookieConfig } from "@/utils/cookie-config";
import { initAnalytics } from "@/libs/analytics";
import { logger } from "@/libs/logger";
import { CSPostHogProvider } from "@/components/cs-posthog-provider";

import consentCss from "vanilla-cookieconsent/dist/cookieconsent.css?url";
import appCss from "@/styles/index.css?url";

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
}>()({
  head: () => ({
    meta: [
      {
        charSet: "utf-8",
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
      ...seo({
        title: "Dinder",
        description: "Dinder is a dating app for your food choices",
      }),
      {
        title: "TanStack Start Starter",
      },
    ],
    links: [
      { rel: "stylesheet", href: appCss },
      { rel: "stylesheet", href: consentCss },
    ],
  }),
  errorComponent: (props) => {
    return (
      <RootDocument>
        <DefaultCatchBoundary {...props} />
      </RootDocument>
    );
  },
  notFoundComponent: () => <NotFound />,
  component: RootComponent,
});

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

function RootComponent() {
  return (
    <RootDocument>
      <Outlet />
    </RootDocument>
  );
}

function RootDocument({ children }: Readonly<{ children: ReactNode }>) {
  const [showDevtools, setShowDevtools] = React.useState(false);
  const [isMounted, setIsMounted] = React.useState(false);

  const cookieConsentInitialized = React.useRef(false);
  const analyticsInitialized = React.useRef(false);

  React.useEffect(() => {
    setIsMounted(true);
    // @ts-expect-error - global
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  React.useEffect(() => {
    if (isMounted && !cookieConsentInitialized.current) {
      cookieConsentInitialized.current = true;

      const initializeAnalytics = (enabled: boolean) => {
        if (!analyticsInitialized.current) {
          analyticsInitialized.current = true;
          initAnalytics(enabled);
        }
      };

      CookieConsent.run({
        onFirstConsent: () => {
          const analyticsEnabled = CookieConsent.acceptedCategory("analytics");
          logger.info("First consent", { analyticsEnabled });
          initializeAnalytics(analyticsEnabled);
        },
        onConsent: () => {
          const analyticsEnabled = CookieConsent.acceptedCategory("analytics");
          logger.info("Consent", { analyticsEnabled });
          initializeAnalytics(analyticsEnabled);
        },
        onChange: () => {
          const analyticsEnabled = CookieConsent.acceptedCategory("analytics");
          logger.info("Change", { analyticsEnabled });
          initializeAnalytics(analyticsEnabled);
        },
        ...cookieConfig,
      });
    }
  }, [isMounted]);

  return (
    <html>
      <head>
        <Meta />
      </head>
      <body>
        <CSPostHogProvider>
          {children}
          <ScrollRestoration />
          <Toaster />
          <ReactQueryDevtools />
          <Suspense>
            <TanStackRouterDevtools />
          </Suspense>
          {showDevtools && (
            <React.Suspense fallback={null}>
              <ReactQueryDevtoolsProduction />
            </React.Suspense>
          )}
        </CSPostHogProvider>
        <Scripts />
      </body>
    </html>
  );
}
