export const cookieConfig = {
  categories: {
    necessary: {
      enabled: true,
      readOnly: true,
    },
    analytics: {},
  },
  language: {
    default: "en",
    translations: {
      en: {
        consentModal: {
          title: "We use cookies",
          description: "We use cookies to improve your experience on our website.",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Accept necessary",
          showPreferencesBtn: "Manage individual preferences",
        },
        preferencesModal: {
          title: "Manage cookie preferences",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Accept necessary",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close",
          sections: [
            {
              title: "Strictly necessary cookies",
              description:
                "These cookies are essential for the website to function properly.",
              linkedCategory: "necessary",
            },
            {
              title: "Performance and Analytics",
              description:
                "These cookies are used to collect information about how you interact with our website and allow us to improve it.",
              linkedCategory: "analytics",
            },
            {
              title: "More information",
              description:
                "For any queries in relation to our policy on cookies, please contact us.",
            },
          ],
        },
      },
    },
  },
};
