import { HTMLMotionProps, motion } from "framer-motion";

export type WithClassName = {
  className?: string;
};

export const MotionDiv = motion.div as React.FC<HTMLMotionProps<"div"> & WithClassName>;
export const MotionH1 = motion.h1 as React.FC<HTMLMotionProps<"h1"> & WithClassName>;
export const MotionH2 = motion.h2 as React.FC<HTMLMotionProps<"h2"> & WithClassName>;
export const MotionH3 = motion.h3 as React.FC<HTMLMotionProps<"h3"> & WithClassName>;
export const MotionP = motion.p as React.FC<HTMLMotionProps<"p"> & WithClassName>;
export const MotionSpan = motion.span as React.FC<
  HTMLMotionProps<"span"> & WithClassName
>;
